import { TableCell } from '@mui/material';


const TableCellAlertedStarted = ({ tagobject, children, rowspan, colspan }) => {

    const status =
    tagobject.value === 0
      ? "stopped"
      : tagobject.value > tagobject.alarma_h_h ||
        tagobject.value < tagobject.alarma_l_l
      ? "danger"
      : tagobject.value > tagobject.alarma_h ||
        tagobject.value < tagobject.alarma_l
      ? "warning"
      : "normal";

  return (
    <TableCell
      sx={[
        status === "danger" && { backgroundColor: "#ac3e31",color: "#fff" },
        status === "warning" && { backgroundColor: "#dbae58" },
        status === "stopped" && { backgroundColor: "#dadada" },
        status === "normal" && { backgroundColor: "#6AB187" },
      ]}
      rowSpan={rowspan}
      colSpan={colspan}
    >
      {children}
    </TableCell>
  )
}

export default TableCellAlertedStarted