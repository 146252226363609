import Loadable from "app/components/Loadable";
import { lazy } from "react";

const Dashboard = Loadable(lazy(() => import("./Dashboard")));

const DashboardRoutes = [
    {
      path: "/dashboard",
      element: <Dashboard />,
      auth: [1,2],
    }]

    export default DashboardRoutes