import { styled } from "@mui/material";
import Grid from "@mui/material/Grid2";

export const CardHeader = styled("div")(() => ({
  margin: "0.875rem",
  fontSize: "0.8rem",
  color: "#34314c",
  fontWeight: 500,
  textTransform: "uppercase",
}));

export const ContentBox = styled("div")(({ theme }) => ({
  margin: "1rem 3rem",
  [theme.breakpoints.down("lg")]: { margin: "1rem" },
  [theme.breakpoints.down("sm")]: { margin: "0.5rem 0rem" },
}));

export const ContentChart = styled("div")(({ theme }) => ({
  marginTop: "0.3rem",
  backgroundColor: "#fff",
  padding: "0.5rem",
  [theme.breakpoints.down("md")]: { margin: "0rem", padding: "0.5rem 0.1rem" },
}));

export const Title = styled("div")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  // textTransform: "capitalize",
  width: "50%",
  paddingLeft:"2rem",
  paddingTop:"0.5rem",
  // paddingBottom:"0.5rem",
  display: "inline-block",
  marginBottom:"0.8rem",
  justifyContent:"center",
  alignItems:"center"
}));

export const SubTitle = styled("span")(({ theme }) => ({
  color: "#34314c",
  fontWeight: 500,
  textTransform: "capitalize",
}));

export const RefreshBox = styled("div")(() => ({
  color: "#34314c",
  fontWeight: 420,
  fontSize: "0.74rem",
  position: "absolute",
  width:"50%",
  display:"inline-block",
  textAlign:"center",
  justifyContent:"center",
  alignItems:"center",
  // margin:"1rem",
  // padding:"0.6rem",
  marginBottom:"0.4rem"

}));

export const DataTable = styled("div")(() => ({
  whiteSpace: "pre",
  "& small": {
    borderRadius: 500,
    boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)",
  },
}));

export const GridData = styled(Grid)(() => ({
  whiteSpace: "pre",
  padding: "10px 5px",
  // ".MuiTableCell-root": {
  //   textAlign: "center",
  // },
  ".titleDensimeter": {
    fontWeight: 600,
    margin: "10px 0px",
  },
}));
